const isSafari = () => {
  return (
    !!navigator.userAgent.match(/safari/i) &&
    !navigator.userAgent.match(/chrome/i) &&
    typeof document.body.style.webkitFilter !== "undefined" &&
    !window.chrome
  )
}

document.addEventListener("DOMContentLoaded", () => {
  if (isSafari()) document.documentElement.classList.add("is-safari")
})
