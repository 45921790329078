if (document.documentElement.classList.contains("cults")) {
  console.info(
    `%c
                         ........
                    .:t.sssssssssstl.
                 .l.sss.tl:::::lt.ssssl.
                lsss.:.            .tssst.
              ..sst.                 .tsss:
             .sssl                     .sss:
             sssl                       .sss.
            lsssltt.....tl:.             tsst
           ..sssss..tt..ssssl            :sss.
         ..ssst:.        .ssl            :ssss.:
       .tss.:            .lsl            .ss..ss..
      .sssl                             lsss. lsss:
     .sssl                            lsss.   .sss.
     tss.                        .ltl:.ss..     lss.
     sss:             ..l        :tssss.:       .sss.
     sss.           :tsss           .l:          sss.
     .ssl          .ssst.                       .sss
     :sss.          .sst                        .sst
      tss..         .sssl                      tss.
       tss.:         ..ss..                  ..ss..
        :.ss.:.        lssst.             .:.sssl
          :.sss.t:.......lssssstl:......:l.sss.l.
            .:t.ssssssssss..tt.ssssssssss.tl.
                ...::::....     ....::::.

Hello there! Looking to help us build Cults? Contact us at
hello@cults3d.com

A few developer shortcuts you can use:
- Ctrl-G: show grid and breakpoints
- Ctrl-L: change language
- Ctrl-K: change currency
- Ctrl-T: switch theme temporarily`,
    "color: #822ef5; font-family: monospace")
}
