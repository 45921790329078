let maxBsaId = 0

const renderBsa = () => {
  const zones = document.querySelectorAll("[data-zone-bsa]")
  if (zones.length == 0) return

  zones.forEach(zone => {
    let id = zone.dataset.zoneBsa
    if (document.getElementById(id)) {
      maxBsaId += 1
      id = `${id}_${maxBsaId}`
    }

    const div = document.createElement("div")
    div.id = id
    zone.appendChild(div)
  })

  if (!window.optimize) insertBsa()

  window.optimize = window.optimize || { queue: [] }
  window.optimize.queue.push(() => {
    window.optimize.pushAll()
  })
}

const insertBsa = () => {
  const date = (new Date() - new Date() % 600_000)

  const script = document.createElement("script")
  script.setAttribute("async", "async")
  script.src = `https://cdn4.buysellads.net/pub/cults3d.js?${date}`
  document.head.appendChild(script)

  window.optimize = window.optimize || { queue: [] }
}

export { renderBsa }
