async function adDetector(success, failure) {
  if (window.canRunAds === true) return success()
  if (window.canRunAds === false) return failure()

  try {
    const url = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
    await fetch(new Request(url, { method: "HEAD", mode: "no-cors" }))
  } catch {
    window.canRunAds = false
    return failure()
  }

  window.canRunAds = true
  success()
}

export { adDetector }
