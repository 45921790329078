import { Turbo } from "@hotwired/turbo-rails"

Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target)
}

Turbo.StreamActions.close_overlay = function () {
  const event = new CustomEvent("overlay:close")
  document.dispatchEvent(event)
}
