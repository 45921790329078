const renderCarbonAuto = () => {
  if (document.getElementById("carbonads")) return

  const zones = document.querySelectorAll("[data-zone-carbon-auto]")
  zones.forEach(zone => {
    appendScript(zone, zone.getAttribute("data-zone-carbon-auto"))
  })
}

const appendScript = (zone, code) => {
  const script = document.createElement("script")
  const url = "//cdn.carbonads.com/carbon.js"
  script.src = `${url}?placement=carbonadsnet&format=cover&serve=${code}`
  script.setAttribute("async", "async")
  script.id = "_carbonads_js"
  zone.appendChild(script)
}

export { renderCarbonAuto }
