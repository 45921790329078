import { adDetector } from "../helpers/zones/ad_detector"
import { renderBsa } from "../helpers/zones/bsa"
import { renderCarbonAuto } from "../helpers/zones/carbon"
import { showFallback, clear } from "../helpers/zones/zones"

function renderAds() {
  renderBsa()
}

function renderNoAds() {
  renderCarbonAuto()
  showFallback()
}

document.addEventListener("turbo:fast-load", () => {
  adDetector(renderAds, renderNoAds)
})

document.addEventListener("turbo:before-cache", clear)
